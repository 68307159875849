import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Button, CardContent, InputAdornment, TextField } from '@mui/material';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { countries } from '../_mock';
import CustomModal from './CustomModal';
import useLocales from '../hooks/useLocales';
import { StyledTextField } from '../layouts/GlobalStyles';
import Iconify from './Iconify';
import palette from '../theme/palette';

const getCountryCodeObj = (code) => {
  if (!code) return null;
  let countryCode = null;
  if (code) {
    const foundCode = countries.find((x) => x?.code === code);
    if (foundCode) countryCode = foundCode;
  }

  return countryCode;
};

const allowedCountries = ['AU', 'CA', 'MY'];

const PhonenumberTextField2 = ({ onChange, value, showCountrySelections = true, ...others }) => {
  const [country, setCountry] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState(value || '');
  const { translate } = useLocales();
  const [openCodeSelect, setOpenCodeSelect] = useState(false);

  const filteredCountries = useMemo(() => countries.filter((x) => allowedCountries.includes(x?.code)), []);

  useEffect(() => {
    // const _countryCode = process.env.REACT_APP_COUNTRY_CODE || 'AU';

    const _country = getCountryCodeObj('AU'); // default country is AU

    if (_country) setCountry(_country);
  }, []);

  useEffect(() => {
    if (value) {
      const phoneNumber = parsePhoneNumberFromString(value, country?.code);

      if (phoneNumber) {
        const _country = filteredCountries.find((c) => c.phone === phoneNumber.countryCallingCode);
        if (_country && _country.code !== country?.code) {
          setCountry(_country);
        }
        setTextFieldValue(phoneNumber.nationalNumber);
      } else if (value !== `+${country.phone}`) {
        setTextFieldValue(value);
      }
    } else {
      setTextFieldValue(value);
    }
  }, [value, country, filteredCountries]);

  const handleCountryChange = (country) => {
    setCountry(country);
    if (country) {
      onChange(`+${country.phone}`);
    }
    handleCloseMenu();
  };

  const handleOpenMenu = () => {
    setOpenCodeSelect(true);
  };

  const handleCloseMenu = () => {
    setOpenCodeSelect(false);
  };

  const handleTextFieldChange = (event) => {
    const phone = event.target.value;
    const phoneNumber = parsePhoneNumberFromString(phone, country?.code);

    if (phoneNumber) {
      onChange(phoneNumber.number);
    } else {
      onChange(phone);
    }
  };

  return (
    <>
      <StyledTextField
        value={textFieldValue}
        sx={{ position: 'relative' }}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          readOnly: others?.readOnly,
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                position: 'relative',
                color: palette.visitor.input.color,
                font: 'inherit',
                fontSize: '0.8rem',
                fontWeight: 900,
                ml: 1,
              }}
            >
              <Button
                variant="text"
                size="small"
                onClick={handleOpenMenu}
                endIcon={<Iconify icon={'mingcute:down-fill'} />}
                sx={{ mr: 0.5, color: palette.visitor.input.color, font: 'inherit', py: 0, fontSize: '0.8rem' }}
                tabIndex={-1}
              >
                +{country?.phone}
              </Button>
            </InputAdornment>
          ),
          sx: {
            ...others?.InputPropStyles,
          },
        }}
        {...others}
        onChange={handleTextFieldChange}
      />
      <CustomModal
        fullWidth
        maxWidth="xs"
        title={translate('select_country')}
        open={openCodeSelect}
        onClose={handleCloseMenu}
      >
        <CardContent>
          <Autocomplete
            fullWidth
            value={country}
            options={filteredCountries}
            getOptionLabel={(option) => `${option.label} +${option.phone}`}
            onChange={(e, v) => handleCountryChange(v)}
            renderInput={(params) => (
              <TextField fullWidth sx={{ '& .MuiOutlinedInput-root': { height: 'unset' } }} {...params} />
            )}
          />
        </CardContent>
      </CustomModal>
    </>
  );
};

PhonenumberTextField2.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  selectCode: PropTypes.func,
  selectAreaCode: PropTypes.func,
};

export default PhonenumberTextField2;
