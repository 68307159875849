/* eslint-disable camelcase */
// @mui
import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { getYear } from 'date-fns';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import palette from '../../theme/palette';

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&': {
    color: 'white',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 700,
  fontSize: '.7rem',
}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: 340,
  height: 60,

  [theme.breakpoints.up('sm')]: {
    width: 340,
    height: 60,
  },
  [theme.breakpoints.down('sm')]: {
    width: '85vw',
    height: '17vw',
    objectFit: 'contain',
  },
}));

const CustomScrollbar = styled(Grid)({
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'grey #2b2b2b',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#2b2b2b',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#f48fb1',
    borderRadius: '10px',
    border: '2px solid #2b2b2b',
  },
});

const Footer = () => {
  const { siteSettings } = useSelector((x) => x.lookup);

  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const {
    config_footer_copyright,
    config_phonenumber,
    // config_telegram,
    // config_wechat,
    // config_whatsapp,
    // config_facebook,
    // config_messenger,
    // config_viber,
    // config_twitter,
    // config_instagram,
    // config_youtube,
    // config_line,
  } = siteSettings || {};

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar('Copied!');
  };

  const copyrightText = useMemo(() => {
    if (siteSettings?.config_footer_copyright) {
      const newText = siteSettings?.config_footer_copyright
        .replace('[year]', getYear(new Date()))
        .replace('[name]', siteSettings?.config_name);

      console.log(newText);

      return newText;
    }

    return '';
  }, [siteSettings?.config_footer_copyright, siteSettings?.config_name]);

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  const displayGameProvidersComponent = (items) => {
    const midIndex = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, midIndex);
    const secondHalf = items.slice(midIndex);

    return (
      <CustomScrollbar
        container
        spacing={1}
        sx={{ overflowX: 'scroll', mt: 2, ml: 0, width: '100%', padding: '0 20px 20px 10px' }}
      >
        <Grid container item xs={12} spacing={1} wrap="nowrap">
          {firstHalf.map((item, index) => (
            <Grid item>
              <Image key={index} src={item} alt="Providers" sx={{ width: '96px', height: '42px' }} />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} spacing={1} wrap="nowrap">
          {secondHalf.map((item, index) => (
            <Grid item>
              <Image key={index} src={item} alt="Providers" sx={{ width: '96px', height: '42px' }} />
            </Grid>
          ))}
        </Grid>
      </CustomScrollbar>
    );
  };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      {/* Player/Winner */}
      {/* <Grid container spacing={1}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box sx={{ backgroundColor: '#343a45', borderRadius: '10px', p: '20px', textAlign: 'center' }}>
            <Stack direction="column" alignItems={'center'} justifyContent={'center'}>
              <Typography sx={{ fontSize: '24px', color: '#437bde', fontWeight: 'bolder' }}>915, 190</Typography>
              <Stack direction="row" justifyContent={'center'} alignItems={'center'} spacing={1}>
                <Iconify icon={'fa:group'} width={25} height={25} sx={{ color: 'rgb(152, 172, 208)' }} />
                <Typography sx={{ fontSize: '14px', color: '#98acd0', fontWeight: 'bolder' }}>Player</Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box sx={{ backgroundColor: '#343a45', borderRadius: '10px', p: '20px', textAlign: 'center' }}>
            <Stack direction="column" alignItems={'center'}>
              <Typography sx={{ fontSize: '24px', color: '#437bde', fontWeight: 'bolder' }}>970, 957</Typography>
              <Stack direction="row" justifyContent={'center'} alignItems={'center'} spacing={1}>
                <Iconify
                  icon={'healthicons:award-trophy'}
                  width={25}
                  height={25}
                  sx={{ color: 'rgb(152, 172, 208)' }}
                />
                <Typography sx={{ fontSize: '14px', color: '#98acd0', fontWeight: 'bolder' }}>Player</Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid> */}

      <Container maxWidth="lg" sx={{ backgroundColor: palette.footer.bgColor, p: '2rem 0 !important' }}>
        <Stack direction="row" alignItems={'center'} spacing={2} sx={{ ml: 2 }}>
          <Typography sx={{ color: 'white', fontWeight: 600, fontSize: '.85rem' }}>
            {translate('our_game_provides')}
          </Typography>
        </Stack>
        {displayGameProvidersComponent(_gameProviders)}
        {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, px: 2, overflowX: 'auto', width: '100%' }}>
          <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
            {_gameProviders.map((item, index) => (
              <Grid item>
                <Image key={index} src={item} alt="Providers" sx={{ width: '6rem' }} />
              </Grid>
            ))}
          </Grid>
        </Box> */}
      </Container>

      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: palette.footer.bgColor2,
          py: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <FooterText sx={{ textTransform: 'uppercase' }}>{translate('Contact Us 24 Hours Service')}</FooterText>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '0.2rem' }}>
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: '50%',
              width: '1.3rem',
              height: '1.3rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: '.2rem',
            }}
          >
            <Iconify icon="heroicons-solid:chat" sx={{ color: 'black', fontSize: '1.2rem' }} />
          </Box>
          <FooterText sx={{ fontSize: '0.5rem' }}>{translate('Live Chat')}</FooterText>
          <FooterText sx={{ fontSize: '0.7rem', fontWeight: 500, color: 'rgb(219, 67, 20)', lineHeight: 1 }}>
            {translate('Chat Now')}
          </FooterText>
        </Box>
      </Container>

      <Container maxWidth="lg" sx={{ p: 2, backgroundColor: palette.footer.bgColor }}>
        <Stack spacing={1}>
          <FooterText>{translate('Gaming License')}</FooterText>
          <Box>
            <Image
              src={require('../../assets/powered/gaming-curacao-logo.png')}
              sx={{ width: '128px', height: '41px' }}
            />
          </Box>

          <FooterText sx={{ fontSize: '0.6rem' }}>
            Victor88 is a licensed and regulated entity by the government of Curacao and operates lawfully under the
            Master License of Gaming Services Provider, N.V #365/JAZ
          </FooterText>
        </Stack>

        <Stack spacing={1} sx={{ mt: 5 }}>
          <FooterText>{translate('Certification')}</FooterText>
          <Box sx={{ display: 'flex' }}>
            <Image src={require('../../assets/powered/bmm.png')} sx={{ width: '45px', height: '26px' }} />
            <Image src={require('../../assets/powered/itech.png')} sx={{ width: '26px', height: '26px' }} />
          </Box>
          <Image src={require('../../assets/powered/verified.png')} sx={{ width: '96px', height: '24px' }} />
        </Stack>

        <Box>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Box>
                <Stack spacing={1} sx={{ mt: 5 }}>
                  <FooterText>{translate('Security')}</FooterText>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                      <Image
                        src={require('../../assets/powered/iovation.png')}
                        sx={{ width: '91px', height: '19px' }}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Image
                        src={require('../../assets/powered/threat-metrix.png')}
                        sx={{ width: '27px', height: '27px' }}
                      />
                      <FooterText sx={{ fontSize: '0.8rem', fontWeight: 300 }}>ThreatMetrix</FooterText>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Stack spacing={1} sx={{ mt: 5 }}>
                  <FooterText>{translate('Responsible Gaming')}</FooterText>
                  <Box sx={{ display: 'flex' }}>
                    <Box>
                      <Image src={require('../../assets/powered/18.png')} sx={{ width: '21px', height: '20px' }} />
                    </Box>
                    <Box sx={{ mx: 1 }}>
                      <Image
                        src={require('../../assets/powered/gamcare-logo.png')}
                        sx={{ width: '16px', height: '21px' }}
                      />
                    </Box>
                    <Box>
                      <Image
                        src={require('../../assets/powered/be-gamble-aware-logo.png')}
                        sx={{ width: '29px', height: '25px' }}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Stack spacing={1} sx={{ mt: 5 }}>
          <FooterText>{translate('Payment Method')}</FooterText>
          <Box style={{ marginTop: 10 }}>
            <Grid container spacing={1}>
              {_paymentMethod.map((src, idx) => (
                <Grid item key={idx}>
                  <StyledImage src={src} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '2rem' }}>
          <StyledTypography sx={{ fontSize: '.5rem' }}>{copyrightText}</StyledTypography>

          <Box sx={{ display: 'flex' }}>
            <Box>
              <Image src={require('../../assets/social/yt.png')} sx={{ width: '23px', height: '24px' }} />
            </Box>
            <Box sx={{ mx: 1 }}>
              <Image src={require('../../assets/social/fb.png')} sx={{ width: '23px', height: '24px' }} />
            </Box>
            <Box>
              <Image src={require('../../assets/social/instagram.png')} sx={{ width: '23px', height: '24px' }} />
            </Box>
          </Box>
        </Box>
        {/* <Grid container spacing={{ sm: 1, xs: 2 }}>
          <Grid item sm={12 / 5} xs={12 / 2}>
            <Stack direction={'column'} spacing={1}>
              <Image
                src={require('../../assets/brand-logo.png')}
                sx={{
                  height: '40px',
                  width: 'auto',
                  maxWidth: '130px',
                  '& img': {
                    objectFit: 'contain',
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item sm={12 / 5} xs={12 / 2}>
            <Stack direction={'column'} spacing={1}>
              <FooterText sx={{ fontWeight: 600 }}>HELPFUL LINKS</FooterText>
              <FooterText>All Games</FooterText>
              <FooterText>Slots</FooterText>
              <FooterText>Live Casino</FooterText>
            </Stack>
          </Grid>
          <Grid item sm={12 / 5} xs={12 / 2}>
            <Stack direction={'column'} spacing={1}>
              <FooterText sx={{ fontWeight: 600 }}>PLATFORM</FooterText>
              <FooterText>Support</FooterText>
              <FooterText>FAQ</FooterText>
              <FooterText>Slots</FooterText>
              <FooterText>Partnetship Program</FooterText>
              <FooterText>Help Center</FooterText>
            </Stack>
          </Grid>
          <Grid item sm={12 / 5} xs={12 / 2}>
            <Stack direction={'column'} spacing={1}>
              <FooterText sx={{ fontWeight: 600 }}>ABOUT US</FooterText>
              <FooterText>AML Policy</FooterText>
              <FooterText>Sports Policy</FooterText>
              <FooterText>Responsible Gaming</FooterText>
              <FooterText>Privacy Policy</FooterText>
              <FooterText>Terms and Conditions</FooterText>
            </Stack>
          </Grid>
          <Grid item sm={12 / 5} xs={12 / 2}>
            <Stack direction={'column'} spacing={1}>
              <FooterText sx={{ fontWeight: 600 }}>COMMUNITY</FooterText>
              <FooterText>AML Policy</FooterText>
              <FooterText>Sports Policy</FooterText>
              <FooterText>Responsible Gaming</FooterText>
              <FooterText>Privacy Policy</FooterText>
              <FooterText>Terms and Conditions</FooterText>
            </Stack>
          </Grid>
        </Grid> */}
      </Container>
    </Box>
  );
};

export default Footer;

const _gameProviders = [
  require('../../assets/game_providers/provider_1.png'),
  require('../../assets/game_providers/provider_2.png'),
  require('../../assets/game_providers/provider_3.png'),
  require('../../assets/game_providers/provider_4.png'),
  require('../../assets/game_providers/provider_5.png'),
  require('../../assets/game_providers/provider_6.png'),
  require('../../assets/game_providers/provider_7.png'),
  require('../../assets/game_providers/provider_8.png'),
  require('../../assets/game_providers/provider_9.png'),
  require('../../assets/game_providers/provider_10.png'),
  require('../../assets/game_providers/provider_11.png'),
  require('../../assets/game_providers/provider_12.png'),
  require('../../assets/game_providers/provider_13.png'),
  require('../../assets/game_providers/provider_14.png'),
  require('../../assets/game_providers/provider_15.png'),
  require('../../assets/game_providers/provider_16.png'),
  require('../../assets/game_providers/provider_17.png'),
  require('../../assets/game_providers/provider_18.png'),
  require('../../assets/game_providers/provider_19.png'),
  require('../../assets/game_providers/provider_20.png'),
  require('../../assets/game_providers/provider_21.png'),
  require('../../assets/game_providers/provider_22.png'),
  require('../../assets/game_providers/provider_23.png'),
  require('../../assets/game_providers/provider_24.png'),
];

const _paymentMethod = [
  require('../../assets/powered/payment/payment_method.png'),
  // require('../../assets/powered/payment/bitcoin.png'),
  // require('../../assets/powered/payment/usdt.png'),
  // require('../../assets/powered/payment/crypto.png'),
  // require('../../assets/powered/payment/help2pay.png'),
  // require('../../assets/powered/payment/eeziepay.png'),
  // require('../../assets/powered/payment/visa.png'),
  // require('../../assets/powered/payment/mastercard.png'),
  // require('../../assets/powered/payment/duitnow.png'),
  // require('../../assets/powered/payment/fpx.png'),
];
