import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Grid, Typography } from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
import useCollapseDrawer from '../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../config';
// redux
import { useDispatch, useSelector } from '../redux/store';
import useAuth from '../hooks/useAuth';

import palette from '../theme/palette';

import Iconify from '../components/Iconify';

import {
  getAngPauPromotion,
  getGameCategsAndCompanies,
  getSiteSettings,
  getUserMemberBalance,
} from '../redux/slices/lookup';

import { setGameCategories } from '../redux/slices/game';

import conObj from '../utils/connection-assistant';

import { usePageVisibility } from '../hooks/usePageVisibility';
import WithdrawFormDialog from './dashboard/header/withdraw/WithdrawFormDialog';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  background: palette.dark.background.default,
  paddingBottom: '64px',
  [theme.breakpoints.only('xs')]: {
    paddingTop: '5px',
  },
  [theme.breakpoints.up('lg')]: {
    // paddingLeft: 8,
    // paddingRight: 8,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingTop: '10px',
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    // ...(collapseClick && {
    //   marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    // }),
  },
}));

// ----------------------------------------------------------------------

const ArrowStyle = styled(IconButton)(({ theme }) => ({
  padding: 6,
  opacity: 0.48,
  color: theme.palette.common.white,
  '&:hover': { opacity: 1 },
}));

const TransactionLayout = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const { collapseClick } = useCollapseDrawer();

  const { siteSettings } = useSelector((x) => x.lookup);

  const isVisible = usePageVisibility();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { themeLayout } = useSettings();

  const verticalLayout = themeLayout === 'vertical';

  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) getUserMemberBalance();
    getGameCategsAndCompanies();
    getSiteSettings();
    getAllGames();
    getAngPauPromotion();

    /**
     * handle capital pay status
     */
    if (localStorage.getItem('orderId')) {
      const orderId = localStorage.getItem('orderId');

      const paymentMethod = JSON.parse(localStorage.getItem('paymentMethod'));

      conObj
        .post(`${paymentMethod?.type}/check_status/${orderId}.json`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data?.data?.status === 1) {
              let variant = 'success';

              if (res.data?.data?.transactionStatus === 'rejected') {
                variant = 'error';
              }
              if (res.data?.data?.transactionStatus === 'pending') {
                variant = 'warning';
              }
              enqueueSnackbar(
                `${paymentMethod?.name} Deposit: Order ID ${orderId} ${res.data?.data?.transactionStatus}`,
                {
                  variant,
                  autoHideDuration: 5000,
                }
              );

              // clear orderId
              localStorage.removeItem('orderId');
              localStorage.removeItem('paymentMethod');
            }
          }
        })
        .catch((err) => {
          // clear orderId
          localStorage.removeItem('orderId');
          localStorage.removeItem('paymentMethod');
          const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
          enqueueSnackbar(err.message, snackbarOptions);
          console.log(`Error: ${err.message}`);
        });
    }
  }, []);

  /**
   * Call getUserMemberBalance() when tab is active and isAuthenticated
   * to keep the member balance updated
   */
  useEffect(() => {
    if (isVisible && isAuthenticated) getUserMemberBalance();
  }, [isVisible, isAuthenticated]);

  const getAllGames = () => {
    conObj.post('games/all.json?limit=1').then((res) => {
      if (res.status === 200) {
        // res.data?.data?.data
        // set appversion to localstorage
        // res.data?.data.appversion
        const _allGames = res.data?.data?.data;

        dispatch(
          setGameCategories(
            _allGames
              .filter((f) => f.key.toLowerCase().indexOf('gcat') !== -1)
              .map((item) => ({ key: item.key, name: item.name, slug: item.slug }))
          )
        );
      }
    });
  };

  const leftIcon = () => (
    <Iconify
      icon={'mingcute:left-fill'}
      sx={{
        width: 20,
        height: 20,
      }}
    />
  );

  const Navigation = () => (
    <Box
      sx={{ width: '100%' }}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      onClick={() => navigate(-1)}
    >
      <ArrowStyle size="small">{leftIcon()}</ArrowStyle>
      <Typography sx={{ fontWeight: 800, fontSize: '18px', cursor: 'pointer' }}>Back</Typography>
    </Box>
  );

  if (verticalLayout) {
    return (
      <>
        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Navigation />
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        // backgroundColor: '#0b1712',
      }}
    >
      <MainStyle collapseClick={collapseClick}>
        <Navigation />
        <Outlet />
      </MainStyle>

      {isAddMemberBankDialog?.open && <WithdrawFormDialog />}
    </Box>
  );
};

export default TransactionLayout;
