import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import { t } from 'i18next';

import { useSelector } from '../redux/store';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
  const { siteSettings } = useSelector((x) => x.lookup);

  return (
    <>
      <Helmet>
        <title data-react-helmet="true">{`${siteSettings?.config_meta_title || 'Victor88Aus'}`}</title>
        {meta}
        <meta name="description" data-react-helmet="true" content={siteSettings?.config_meta_desc} />;
      </Helmet>

      <Box ref={ref} {...other} className="page-maxwidth">
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
