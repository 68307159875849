import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Dialog, TextField, NativeSelect, Stack, Typography, styled } from '@mui/material';

import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import { useDispatch, useSelector } from '../../../../redux/store';
import { openAddMemberBankDialog } from '../../../../redux/slices/addMemberBankDialog';

const CloseButton = styled(Iconify)(({ theme }) => ({
  cursor: 'pointer',
  position: 'absolute',
  right: '16px',
  top: '16px',
  color: 'rgb(147, 172, 211)',
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  color: '#fff',
  fontWeight: 700,
  fontSize: 14,
  flexGrow: 1,
  width: '100%',
  marginTop: 8,
  '& input': {
    fontWeight: 600,
    '&::placeholder': {
      color: 'rgb(255, 255, 255, .3)',
    },
  },
}));

const StyledSelect = styled(NativeSelect)(({ theme }) => ({
  width: '100%',
  color: '#fff',
  fontWeight: 600,
  padding: '4px 11px',
  mt: 8,
  border: '0.5px solid #2065D1',
  borderRadius: 8,
  '.MuiSvgIcon-root ': {
    fill: '#fff !important',
  },
}));

const StyledDialogLabel = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  color: 'white',
  fontWeight: 'bolder',
}));

const InputLable = styled(Typography)(({ theme }) => ({
  color: 'white',
}));

const RequiredField = () => <span style={{ color: 'red', marginRight: 5 }}>*</span>;

const InputError = ({ children }) => <span style={{ fontSize: '.75rem', color: 'red' }}>{children}</span>;

const WithdrawFormDialog = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const [bankList, setBankList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [newBankAccountForm, setNewBankAccountForm] = useState({
    accountName: '',
    bank: '',
    accountNumber: '',
  });

  useEffect(() => {
    getBankList();
  }, []);

  useEffect(() => {
    console.log(`newBankAccountForm = ${JSON.stringify(newBankAccountForm)}`);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBankAccountForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const getBankList = () => {
    conObj
      .post('transactions/supported_banks.json')
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            setBankList(res.data?.data?.data?.[0]?.Bank || []);
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
        enqueueSnackbar(err?.message, { variant: 'error', autoHideDuration: 5000 });
      });
  };

  const addMemberBank = () => {
    if (isValid()) {
      setIsButtonDisabled(true);

      const requestData = {
        'member_bank-type': 'bank',
        'member_bank-bank_id': newBankAccountForm?.bank,
        'member_bank-account_name': newBankAccountForm?.accountName.trim(),
        'member_bank-account_number': newBankAccountForm?.accountNumber.trim(),
        'member_bank-bank_branch': newBankAccountForm?.bankBranch.trim(),
        'member_bank-pay_id': newBankAccountForm?.payID.trim(),
      };

      conObj
        .post('members/bank_add.json', requestData)
        .then((res) => {
          if (res.status === 200) {
            if (res.data?.data?.status === 1) {
              isAddMemberBankDialog?.onClose();

              dispatch(openAddMemberBankDialog({ open: false, onClose: null }));
            }
          }
        })
        .catch((err) => {
          setIsButtonDisabled(false);
          console.log(`Server Error: ${err.message}`);
          enqueueSnackbar(err?.message, { variant: 'error', autoHideDuration: 5000 });
        });
    }
  };

  const isValid = () => {
    let valid = true;

    const _errors = {};

    if (!newBankAccountForm.accountName.trim()) {
      valid = false;
      _errors.accountName = translate('x_is_required', { x: translate('account_name') });
    } else if (newBankAccountForm.accountName.trim().length < 5) {
      valid = false;
      _errors.accountName = translate('account_name_must_be_4');
    } else {
      _errors.accountName = '';
    }

    if (!newBankAccountForm.bank) {
      valid = false;
      _errors.bank = translate('please_select_x', { x: translate('bank_account') });
    } else {
      _errors.bank = '';
    }

    const accNumberLenght = newBankAccountForm.accountNumber.trim().length;
    const selectedBank = bankList.find((f) => f.id === newBankAccountForm?.bank);

    if (!newBankAccountForm.accountNumber.trim()) {
      valid = false;
      _errors.accountNumber = translate('x_is_required', { x: translate('account_number') });
    } else if (parseInt(selectedBank?.min_length, 10) > 0 || parseInt(selectedBank?.max_length, 10) > 0) {
      if (parseInt(selectedBank?.min_length, 10) > 0 && accNumberLenght < parseInt(selectedBank?.min_length, 10)) {
        valid = false;
        _errors.accountNumber = translate('minimum_length_is_x', { x: selectedBank?.min_length });
      } else if (
        parseInt(selectedBank?.max_length, 10) > 0 &&
        accNumberLenght > parseInt(selectedBank?.max_length, 10)
      ) {
        valid = false;
        _errors.accountNumber = translate('maximum_length_is_x', { x: selectedBank?.max_length });
      } else {
        _errors.accountNumber = '';
      }
    } else {
      _errors.accountNumber = '';
    }

    setErrors(_errors);

    return valid;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{ minHeight: 500 }}
      PaperProps={{
        sx: {
          backgroundColor: '#252631',
        },
      }}
      className="modal-container container-no-scrollbar"
      open={isAddMemberBankDialog?.open}
      onClose={() => dispatch(openAddMemberBankDialog({ open: false, onClose: null }))}
    >
      {/* Title */}
      <Box sx={{ pt: 3, px: 3, background: '#252631' }}>
        <StyledDialogLabel>{translate('withdraw_information')}</StyledDialogLabel>
        <CloseButton
          icon={'ep:close'}
          width={24}
          height={24}
          onClick={() => dispatch(openAddMemberBankDialog({ open: false, onClose: null }))}
        />
      </Box>

      <Box sx={{ p: 3 }}>
        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <InputLable>
              <RequiredField />
              {translate('bank')}
            </InputLable>
          </Stack>

          <StyledSelect
            name="bank"
            value={newBankAccountForm?.bank}
            onChange={(e) => handleChange(e)}
            disableUnderline={true}
          >
            <option value="" />
            {bankList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </StyledSelect>
          {errors?.bank && <InputError>{errors?.bank}</InputError>}
        </Box>

        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <InputLable>
              <RequiredField />
              {translate('account_holder_name')}
            </InputLable>
          </Stack>

          <StyledInput
            value={newBankAccountForm.accountName}
            name="accountName"
            onChange={(e) => handleChange(e)}
            placeholder={translate('name_of_the_account_holder')}
          />
          {errors?.accountName && <InputError>{errors?.accountName}</InputError>}
        </Box>

        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <InputLable>
              <RequiredField />
              {translate('account_number')}
            </InputLable>
          </Stack>

          <StyledInput
            value={newBankAccountForm?.accountNumber}
            name="accountNumber"
            onChange={(e) => handleChange(e)}
            placeholder={translate('fill_in_bank_account')}
          />
          {errors?.accountNumber && <InputError>{errors?.accountNumber}</InputError>}
        </Box>

        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <InputLable>
              {/* <RequiredField /> */}
              {translate('Bank BSB')}
            </InputLable>
          </Stack>

          <StyledInput
            value={newBankAccountForm?.bankBranch}
            name="bankBranch"
            onChange={(e) => handleChange(e)}
            placeholder={translate('Enter Bank Branch')}
          />
          {errors?.bankBranch && <InputError>{errors?.bankBranch}</InputError>}
        </Box>

        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <InputLable>
              {/* <RequiredField /> */}
              {translate('Pay ID')}
            </InputLable>
          </Stack>

          <StyledInput
            value={newBankAccountForm?.payID}
            name="payID"
            onChange={(e) => handleChange(e)}
            placeholder={translate('Enter Pay ID')}
          />
          {errors?.payID && <InputError>{errors?.payID}</InputError>}
        </Box>

        <button
          onClick={() => {
            addMemberBank();
            // setOpenWithdrawForm(false);
            // setWithdrawInfoSaved(true);
          }}
          disabled={isButtonDisabled}
          style={{
            width: '100%',
            height: '42px',
            lineHeight: 1.4,
            position: 'relative',
            textAlign: 'center',
            background: 'linear-gradient(180deg, #04BEFE 0%, #4086ED 100%)',
            color: '#fff',
            fontSize: '17px',
            fontFamily: 'sans-serif',
            border: 'none',
            padding: '10px 14px',
            borderRadius: '10px',
            fontWeight: 'bold',
            marginRight: 8,
            cursor: 'pointer',
            marginBottom: 8,
            marginTop: '20px',
          }}
        >
          {translate('save')}
        </button>
      </Box>
    </Dialog>
  );
};

export default WithdrawFormDialog;
